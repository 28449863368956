.resultsSidebarContent {
    padding: 16px 20px 25px 16px;
}

.resultsSidebarContent .resultsHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 8px;
}

.resultsSidebarContent .resultsHeader .resultsHeaderTitle {
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #464e54;
}

.resultsSidebarContent
    .resultsHeader
    .resultsHeaderFilters
    .resultsHeaderFiltersToggle {
    width: max-content;
    max-width: 104px;
    padding: 4px;
    border-radius: 4px;
    background-color: #e8ebed;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    float: right;
}

.resultsSidebarContent
    .resultsHeader
    .resultsHeaderFilters
    .resultsHeaderFiltersToggle
    > div {
    width: 48px;
    height: 32px;
    padding: 4px 12px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
}

.resultsSidebarContent
    .resultsHeader
    .resultsHeaderFilters
    .resultsHeaderFiltersToggle
    > div.active {
    background-color: #fff;
}

.resultsSidebarContent .resultsBody {
    overflow-y: scroll;
    height: calc(100vh - 265px);
}

.resultsSidebarContent[data-size='1'] .resultsBody {
    height: calc(100vh - 130px);
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media (max-width: 991px) {
    .resultsSidebarContent {
        padding-top: 48px;
        padding-bottom: 16px;
    }
    .resultsSidebarContent .resultsHeader {
        margin-top: 16px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .resultsSidebarContent .resultsBody {
        height: calc(100vh - 283px);
    }
}
