.userProfileNav {
    margin-right: 16px;
    background-color: #fff;
    height: 68px;
}

.userProfileNav > div {
    position: relative;
    background-color: #fff;
}

.userProfileNav.opened > div {
    border-radius: 8px;
    box-shadow: 0 2px 4px -1px rgba(204, 209, 214, 0.2),
        0 1px 10px 0 rgba(204, 209, 214, 0.2),
        0 4px 5px 0 rgba(162, 171, 181, 0.2);
}

.userProfileRow {
    padding: 16px;
}

.userProfileIcon {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    padding: 6px;
    background-color: #e8ebed;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 8px;
    cursor: pointer;
}

.userProfileIcon svg {
    font-size: 16px;
}

.userProfileName {
    display: inline-block;
    font-family: AvenirLTPro;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.27px;
    color: #464e54;
    margin-right: 15px;
    cursor: pointer;
    transition: 0.3s;
}

.userProfileName:hover {
    transition: 0.3s;
    opacity: 0.85;
}

.userProfileArrow {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
}

.userProfileArrow svg {
    font-size: 16px;
}

.userProfileMenu li {
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #7b8a9a;
    padding: 12px 16px;
    transition: 0.3s;
    cursor: pointer;
}

.userProfileMenu li:hover {
    background-color: #f1f2f4;
    transition: 0.3s;
}

.sidebarName {
    font-family: AvenirLTPro;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.27px;
    color: #464e54;
    margin-top: 29px;
    margin-top: 16px;
    margin-bottom: 74px;
}

.sidebarNav li {
    font-family: AvenirLTPro;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.3px;
    color: #7b8a9a;
    padding: 12px 0px;
}

.sidebarNav li:hover {
    color: #464e54;
}

.sidebarNav li svg {
    display: inline-block;
    margin-right: 8px;
    position: relative;
    top: -2px;
}

.sidebarLogo svg {
    width: 100%;
    position: absolute;
    bottom: 79px;
}

.userProfileRow .p-sidebar-content {
    height: inherit;
}

.settingsModal .p-dialog-mask.p-component-overlay {
    background-color: rgba(255, 255, 255, 0.95);
}

.settingsModal .p-dialog {
    box-shadow: inherit;
    max-height: 100%;
    width: 100vw;
    max-width: 1376px;
    margin: 0 auto;
}

.settingsModal .dialog-demo .p-button {
    margin: 0 0.5rem 0 0;
    min-width: 10rem;
}

.settingsModal .dialog-demo p {
    margin: 0;
    line-height: 1.5;
}

.settingsModal .dialog-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.settingsModal .dialog-demo .p-dialog .p-button {
    min-width: 6rem;
}

.settingsModal .p-dialog .p-dialog-header,
.settingsModal .p-dialog .p-dialog-content {
    background: transparent;
}

.settingsModal .p-dialog .p-dialog-header .p-dialog-title {
    visibility: hidden;
}

.settingsModal .p-dialog-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.settingsModal .p-dialog-content::-webkit-scrollbar {
    display: none;
}

.settingsModal .p-dialog .p-dialog-header .p-dialog-header-icon span {
    font-size: 22px;
    color: #a2abb5;
}

.settingsModal .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: inherit;
}

.settingsModal .p-dialog,
.settingsModal .p-dialog .p-dialog-content {
    height: 100%;
    max-height: 100%;
}

.settingsModal .profileSidebarContent {
    padding: 53px 16px 16px;
    height: 100%;
}

.settingsModal .profileSidebarContent .Name,
.settingsModal .profileSidebarContent .Email {
    margin-bottom: 40px;
}

.settingsModal .profileSidebarContent .Languages {
    padding: 0;
    border: 0;
}

.settingsModal .profileSidebarContent .Languages .p-field-radiobutton {
    padding: 12px 16px;
    border-radius: 4px;
    border: solid 1px #e8ebed;
    margin-bottom: 8px;
    position: relative;
}

.settingsModal
    .profileSidebarContent
    .Languages
    .p-field-radiobutton
    .p-radiobutton {
    position: absolute;
    right: 16px;
}

.settingsModal .profileSidebarContent .Languages .p-field-radiobutton > label {
    margin-left: 0;
}

.settingsModal .profileSidebarContent .Languages .label {
    margin-bottom: 8px;
}

.settingsModal .p-dialog-mask.p-component-overlay {
    background-color: #fff;
}

.p-component-overlay.p-sidebar-mask {
    display: none;
}

@media (max-width: 991px) {
    .userProfileHiddenMobile,
    .userProfileMenu {
        display: none;
    }
    .userProfileIcon {
        margin: 0;
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
    }
    .userProfileIcon svg {
        width: 30px;
        height: 30px;
        font-size: 30px;
        margin: 0 auto;
    }
    .userProfileRow {
        padding: 0;
    }
    .userProfileNav {
        margin: 0;
        height: auto;
    }
    .userProfileNav.opened > div {
        box-shadow: inherit;
    }
    .settingsModal .p-dialog .p-dialog-header {
        padding: 16px;
        background-color: #4072ee;
    }
    .settingsModal .p-dialog .p-dialog-header .p-dialog-title {
        visibility: visible;
        font-family: AvenirLTPro;
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        width: 100%;
    }
    .settingsModal .p-dialog .p-dialog-header-icons {
        position: absolute;
        top: 16px;
        right: 16px;
    }
    .settingsModal .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 16px;
        height: 16px;
    }
    .settingsModal .p-dialog .p-dialog-header .p-dialog-header-icon span {
        font-size: 16px;
        color: #fff;
    }
    .settingsModal
        .p-dialog
        .p-dialog-header
        .p-dialog-header-icon:enabled:hover {
        background: transparent;
    }
    .settingsModal .p-dialog .p-dialog-content {
        padding: 0;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .userProfileNav.opened > div {
        width: 212px;
    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    .userProfileNav {
        margin-right: 0;
    }
    .userProfileNav.opened .userProfileHiddenMobile .userProfileArrow {
        position: absolute;
        top: 20px;
        right: 16px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .userProfileNav {
        width: 100px;
    }
    .userProfileNav.opened {
        width: auto;
    }
}

@media (min-width: 1200px) and (max-width: 1469px) {
    .userProfileName {
        display: none;
    }
    .userProfileNav.opened .userProfileHiddenMobile .userProfileArrow {
        position: absolute;
        top: 20px;
        right: 16px;
    }
}

/* .prUserProfile, .prUserProfileMenu & .prUserProfileButton */

.prUserProfile {
    position: relative;
}

.prUserProfile .p-menu {
    position: absolute;
    top: 45px !important;
    left: 0 !important;
}

.prUserProfile .userProfileIcon {
    position: absolute;
    left: 16px;
    margin-right: 0;
}

.prUserProfileButton.p-button {
    border: 0;
    box-shadow: inherit;
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.27px;
    color: #464e54;
    transition: 0.3s;
    z-index: 1002;
    padding-left: 60px;
}

.prUserProfileButton.p-button:enabled:hover,
.prUserProfileButton.p-button:enabled:active {
    color: #464e54;
    border: 0;
    background: transparent;
    box-shadow: inherit;
    transition: 0.3s;
}

.prUserProfileButton.p-button .p-button-icon-right {
    color: #a2abb5;
    font-size: 24px;
    margin-left: 15px;
}

.prUserProfileMenu.p-menu.p-menu-overlay {
    width: 211.89px;
    padding: 0;
    margin-top: -54px;
    padding-top: 42px;
    border-radius: 8px;
    box-shadow: 0 2px 4px -1px rgb(204 209 214 / 20%),
        0 1px 10px 0 rgb(204 209 214 / 20%), 0 4px 5px 0 rgb(162 171 181 / 20%);
}

.prUserProfileMenu.p-menu.p-menu-overlay + div .prUserProfileButton {
    min-width: 215px;
    justify-content: flex-end;
}

.prUserProfileMenu.p-menu.p-menu-overlay
    + div
    .prUserProfileButton
    .p-button-label {
    display: none;
}

.prUserProfileMenu.p-menu .p-menuitem-link {
    display: block;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    padding: 12px 16px;
    transition: 0.3s;
    cursor: pointer;
}

.prUserProfileMenu.p-menu .p-menuitem-link:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.prUserProfileMenu.p-menu .p-menuitem-link:hover {
    background-color: #f1f2f4;
}

.prUserProfileMenu.p-menu .p-menuitem-link .p-menuitem-text,
.prUserProfileMenu.p-menu .p-menuitem-link:hover .p-menuitem-text {
    color: #7b8a9a;
}

.prUserProfileMenu.p-menu .p-menuitem-link:focus {
    box-shadow: inherit;
}

@media (max-width: 991px) {
    .prUserProfileButton.p-button .p-button-label {
        display: none;
    }
}

/*
@media (min-width: 1200px) and (max-width: 1469px) {
    .prUserProfile .p-menu {
        margin-left: -40px;
        margin-left: -19px;
    }
    .prUserProfileButton.p-button .p-button-label {
        display: none;
    }
    .prUserProfileMenu.p-menu .p-menuitem-link {
        text-align: right;
    }
    .prUserProfileMenu.p-menu.p-menu-overlay {
        width: 170px;
        width: 135px;
    }
}
*/
