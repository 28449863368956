.mainprojectSections {
    display: flex;
    flex-wrap: wrap;
}

.mainprojectSections h4 {
    font-size: 20px;
    color: #7e8a97;
    letter-spacing: 1.52px;
}

.mainprojectSections .img-box {
    height: 240px;
    margin: 8px 0 0;
}

.mainprojectSections .img-container {
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    overflow: hidden;
}

.mainprojectSections .double-height {
    height: 480px;
}

.mainprojectSections .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
}

.mainprojectSections .img-label {
    position: absolute;
    left: 16px;
    bottom: 16px;
    padding: 4px 13px;
    opacity: 0.9;
    border-radius: 2px;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    background-color: #fff;
    font-size: 12px;
    letter-spacing: 0.17px;
    color: #535b64;
}

.mainprojectSections .view-more-button {
    text-transform: uppercase;
    font-weight: 500;
}

@media (max-width: 767px) {
    .mainprojectSections h4 {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #7b8a9a;
    }
}

