.viewAll span {
    transition: all 0.3s;
    cursor: pointer;
}

.viewAll span:hover {
    transition: all 0.3s;
    color: #212529;
}

.dialogMapSideLegend .p-dialog-mask.p-component-overlay {
    background-color: rgba(255, 255, 255, 0.95);
}

.dialogMapSideLegend .p-dialog {
    box-shadow: inherit;
    max-height: 100%;
    width: 100vw;
    max-width: 1376px;
    margin: 0 auto;
}

.dialogMapSideLegend .dialog-demo .p-button {
    margin: 0 0.5rem 0 0;
    min-width: 10rem;
}

.dialogMapSideLegend .dialog-demo p {
    margin: 0;
    line-height: 1.5;
}

.dialogMapSideLegend .dialog-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialogMapSideLegend .dialog-demo .p-dialog .p-button {
    min-width: 6rem;
}

.dialogMapSideLegend .p-dialog .p-dialog-header,
.dialogMapSideLegend .p-dialog .p-dialog-content {
    background: transparent;
}

.dialogMapSideLegend .p-dialog .p-dialog-header .p-dialog-title {
    visibility: hidden;
}

.dialogMapSideLegend .p-dialog-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dialogMapSideLegend .p-dialog-content::-webkit-scrollbar {
    display: none;
}

.dialogMapSideLegend .p-dialog-content * {
    font-family: AvenirLTPro;
}

.dialogMapSideLegend .p-dialog .p-dialog-header .p-dialog-header-icon span {
    font-size: 22px;
    color: #a2abb5;
}

.dialogMapSideLegend .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: inherit;
}

.modalBodyFirstSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-bottom: 84px;
}

.modalBodyFirstSection > div {
    background-color: #fff;
    padding: 27px 35px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 0.1);
    display: grid;
    grid-template-columns: 85px auto;
    grid-column-gap: 27px;
    align-items: center;
}

.modalBodyFirstSection .card {
    position: relative;
}

.modalBodyFirstSection .card .p-label {
    position: absolute;
    left: 0;
    width: 100%;
    height: 24px;
    top: calc(50% - 12px);
    /* font-size: 22px; 2 colonne in grid */
    font-size: 19px; /* 3 colonne in grid */
    font-weight: 900;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: center;
    color: #748aa1;
}

.modalBodyFirstSection .text {
    position: relative;
    z-index: 10;
}

.modalBodyFirstSection .text-black-600 {
    display: block;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.12;
    color: #464e54;
    margin-bottom: 4px;
}

.modalBodyFirstSection .text-gray-400 {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #7b8a9a;
    line-height: 1;
    letter-spacing: 0.3px;
}

.modalBodySecondSectionTitle,
.modalBodySecondSectionDate {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.15px;
    text-align: center;
    color: #7e8a97;
}

.modalBodySecondSectionTitle {
    font-size: 32px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

.modalBodySecondSectionDate {
    margin-bottom: 57px;
}

.modalBodySecondSectionCharts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 144px;
    grid-row-gap: 144px;
    margin-bottom: 150px;
    margin-bottom: 100px;
}

.modalBodySecondSectionStores {
    margin-bottom: 32px;
}

.modalBodySecondSectionStoresNumber {
    font-size: 56px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: 0.78px;
    text-align: center;
    color: #464e54;
    margin-bottom: 3px;
}

.modalBodySecondSectionStoresText {
    font-size: 21px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: 0.29px;
    text-align: center;
    color: #748aa1;
}

.gridChart {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-column-gap: 56px;
    align-items: center;
}

.gridChart.left {
    grid-template-columns: 250px 1fr;
}

.gridChart.right {
    grid-template-columns: 1fr 250px;
}

.gridChart ul li {
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #464e54;
    margin-bottom: 32px;
}

.gridChart ul li .circle {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 18px 0 0;
    border-radius: 15px;
    border: 6px solid;
    line-height: 1;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.gridChart ul li:last-child {
    margin-bottom: 0;
}

.gridChart canvas {
    max-width: 250px;
    max-height: 250px;
    margin: 0 auto;
}

.modalHeader {
    background-color: #4072ee;
    padding: 16px;
}

.modalHeaderIcon svg {
    width: 72px;
    height: 72px;
    font-size: 72px;
    margin: 0 auto;
    opacity: 0.2;
}

.modalHeaderTitle {
    font-family: AvenirLTPro;
    font-size: 56px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.78px;
    text-align: center;
    color: #fff;
    margin-bottom: 3px;
}

.modalHeaderSubtitle {
    font-family: AvenirLTPro;
    font-size: 21px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.29px;
    text-align: center;
    color: #fff;
    margin-bottom: 44px;
}

.modalHeaderDate {
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.35px;
    text-align: center;
    color: #0f39a4;
}

.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-mainBlue {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 114, 238, var(--tw-bg-opacity));
}

.text-mainBlue {
    --tw-text-opacity: 1;
    color: rgba(64, 114, 238, var(--tw-text-opacity));
}

@media (max-width: 991px) {
    .dialogMapSideLegend .p-dialog .p-dialog-header {
        padding: 16px;
        background-color: #4072ee;
    }
    .dialogMapSideLegend .p-dialog .p-dialog-header .p-dialog-title {
        visibility: visible;
        font-family: AvenirLTPro;
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        width: 100%;
    }
    .dialogMapSideLegend .p-dialog .p-dialog-header-icons {
        position: absolute;
        top: 16px;
        right: 16px;
    }
    .dialogMapSideLegend .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 16px;
        height: 16px;
    }
    .dialogMapSideLegend .p-dialog .p-dialog-header .p-dialog-header-icon span {
        font-size: 16px;
        color: #fff;
    }
    .dialogMapSideLegend
        .p-dialog
        .p-dialog-header
        .p-dialog-header-icon:enabled:hover {
        background: transparent;
    }
    .dialogMapSideLegend .p-dialog .p-dialog-content {
        padding: 0;
    }
    .modalBodyFirstSection {
        grid-template-columns: 1fr;
        grid-row-gap: 8px;
        padding: 0 16px;
        margin: 16px 0 8px;
    }
    .modalBodyFirstSection > div {
        padding: 27px 25px;
        margin-bottom: 0;
        grid-template-columns: 100px auto;
        grid-column-gap: 28px;
    }
    .modalBodySecondSection {
        padding: 0 16px;
    }
    .modalBodyFirstSection > div:last-child {
        margin-bottom: 0;
    }
    .modalBodySecondSectionTitle,
    .modalBodySecondSectionSubtitle,
    .modalBodySecondSectionDate,
    .modalBodySecondSectionStores {
        display: none;
    }
    .modalBodySecondSectionCharts {
        grid-template-columns: 1fr;
        grid-row-gap: 8px;
        margin-bottom: 16px;
    }
    .gridChart {
        grid-template-columns: 1fr;
        grid-row-gap: 32px;
        box-shadow: 0px 2px 10px rgb(0 0 0 / 0.1);
        padding: 25px 0;
    }
    .gridChart ul {
        text-align: center;
    }
    .gridChart ul li {
        display: inline-block;
        font-size: 16px;
        margin-right: 16px;
        margin-bottom: 16px;
    }
    .gridChart ul li:first-child,
    .gridChart ul li:last-child {
        margin-bottom: 0;
    }
    .gridChart ul li span.circle {
        width: 16px;
        height: 16px;
        border-width: 8px;
        margin-right: 8px;
    }
    .gridChart canvas {
        max-width: 210px;
        max-height: 210px;
    }
}

@media (max-width: 991px) {
    .bg-mainBlue {
        margin-right: 8px;
    }
    .modalBodyFirstSection .text-gray-400 {
        line-height: 1.5;
    }
    .modalBodyFirstSection .card .p-label {
        font-size: 22px;
    }
}

@media (min-width: 992px) {
    @media (max-height: 930px) {
        .modalBodyFirstSection {
            margin-bottom: 30px;
        }
        .modalBodySecondSectionStores {
            margin-bottom: 0;
        }
        .modalBodySecondSectionDate {
            margin-bottom: 15px;
        }
        .modalBodySecondSectionCharts {
            margin-bottom: 30px;
        }
    }
}

@media (min-width: 992px) and (max-width: 1180px) {
    .gridChart {
        grid-column-gap: 24px;
    }
    .gridChart ul li {
        font-size: 18px;
        line-height: 24px;
    }
    .gridChart ul li .circle {
        width: 15px;
        height: 15px;
        border-width: 3px;
        margin-right: 5px;
        top: -2px;
    }
}

@media (min-width: 1181px) and (max-width: 1376px) {
    .modalBodySecondSectionCharts {
        padding: 0 30px;
    }
}

@media (min-width: 1375px) {
    .modalBodySecondSectionCharts {
        padding: 0 100px;
    }
    .gridChart ul li {
        line-height: 24px;
    }
}
