@font-face {
    font-family: 'AvenirLTPro';
    font-style: normal;
    font-weight: 500;
    src: local('AvenirLTPro'),
        url(./fonts/AvenirLTProMedium.otf) format('opentype');
}

* {
    font-family: 'AvenirLTPro' !important;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}

:root {
    -moz-tab-size: 4;
    tab-size: 4;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
}


p,
pre,
figure,
blockquote {
    margin: 0;
}

hr {
    height: 0;
    margin: 0;
    color: inherit;
    border-top-width: 1px;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
select {
    text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

:-moz-focusring {
    outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
    box-shadow: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

button {
    background-color: transparent;
    background-image: none;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

img {
    border-style: solid;
}

textarea {
    resize: vertical;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #9ca3af;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
    color: #9ca3af;
}

button,
[role='button'] {
    cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle;
}

img,
video {
    max-width: 100%;
    height: auto;
}

a {
    color: inherit;
    text-decoration: inherit;
}

i,
span.pi {
    font-family: 'primeicons' !important;
}

.container {
    width: 100%;
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-8 {
    margin-top: 2rem;
}

.p-2 {
    padding: 0.5rem;
}

.p-4 {
    padding: 1rem;
}

.pt-4 {
    padding-top: 1rem;
}

.w-3 {
    width: 0.75rem;
}

.w-80 {
    width: 20rem;
}

.h-3 {
    height: 0.75rem;
}

.left-0 {
    left: 0px;
}

.right-0 {
    right: 0px;
}

.bottom-0 {
    bottom: 0px;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.inline-flex {
    display: inline-flex;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: flex-end;
}

.rounded {
    border-radius: 0.25rem;
}

.rounded-2xl {
    border-radius: 1rem;
}

.rounded-full {
    border-radius: 9999px;
}

.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.leading-none {
    line-height: 1;
}

.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-core {
    --tw-bg-opacity: 1;
    background-color: rgba(54, 212, 166, var(--tw-bg-opacity));
}

.bg-hosted {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 123, 131, var(--tw-bg-opacity));
}

.bg-outlet {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 209, 0, var(--tw-bg-opacity));
}

.bg-noChannelOfTrade {
    --tw-bg-opacity: 1;
    background-color: rgba(232,235,237, var(--tw-bg-opacity));
}

/* Hide scrollbar for Chrome, Safari and Opera */
.searchingForContainer::-webkit-scrollbar {
    display: none;
}

@media (max-width: 991px) {
    .styleFilterAccordion .p-accordion-tab .p-toggleable-content {
        padding: 0;
    }
    .styleFilterAccordion .p-accordion-content .p-grid,
    .styleFilterAccordion .p-accordion-content .p-grid .p-field-checkbox {
        margin: 0;
    }
    .styleFilterAccordion
        .p-accordion-content
        .p-grid
        .p-field-checkbox
        > .p-grid {
        margin: 0;
        justify-content: flex-end;
        flex-direction: row-reverse;
    }
    .styleFilterAccordion
        .p-accordion-content
        .p-grid
        .p-field-checkbox
        > .p-grid
        .p-col-align-start {
        max-width: 85%;
        margin-top: 5px;
    }
    .styleFilterAccordion
        .p-accordion-content
        .p-grid
        .p-field-checkbox
        > .p-grid
        .p-col-align-start
        > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.p-scrollpanel.pvscroll .p-scrollpanel-wrapper {
    border-right: 10px solid var(--surface-b);
}

.p-scrollpanel.pvscroll .p-scrollpanel-content {
    width: calc(100% + 26px) !important;
}

.p-scrollpanel.pvscroll .p-scrollpanel-bar-y {
    background-color: var(--primary-color);
    opacity: 1;
    transition: background-color 0.2s;
}

.p-scrollpanel.pvscroll .p-scrollpanel-bar:hover {
    background-color: #007ad9;
}

.p-dialog-header-icon:focus {
    box-shadow: none !important;
    outline: none !important;
}

#container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 100%;
    position: initial;
}

#container .zoom {
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    background-color: #ddd;
}

#container .zoom img {
    max-height: 100%;
}

.transition {
    transition: -moz-transform ease 200ms;
    transition: -ms-transform ease 200ms;
    transition: -o-transform ease 200ms;
    transition: -webkit-transform ease 200ms;
    transition: transform ease 200ms;
}

.fullScreenHeight {
    height: 100vh;
}

.p-dropdown-panel .p-dropdown-header {
    padding: 1rem;
}

.detail-page-loader {
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(100vh - 110px);
}

.detail-page-loader .p-progress-spinner {
    margin-top: -110px;
}

.detail-page-loader .p-progress-spinner-svg {
    max-width: 65px;
}

.p-tooltip .p-tooltip-text {
    font-size: 14px;
    font-weight: normal;
}

.App-header {
    overflow: hidden;
}

@media (max-width: 767px) {
    header.App-header .gm-style .gmnoprint.gm-bundled-control {
        top: 20px !important;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    header.App-header .gm-style .gmnoprint.gm-bundled-control {
        top: 20px !important;
    }
}

@media (max-width: 991px) {
    .p-sidebar .p-scrollpanel.pvscroll {
        height: 100% !important;
    }
    .p-sidebar .p-scrollpanel.pvscroll .p-scrollpanel-bar-y {
        background-color: transparent;
    }
    .store-carousel .carousel-item {
        width: 100% !important;
        height: auto;
        position: fixed;
        bottom: 0;
        z-index: 25;
        background-color: #fff;
        padding: 5px 0px;
    }
    .p-toolbar-group-left .selectFilter {
        width: 20px;
        height: 20px;
        margin-top: 7px;
    }
    .p-toolbar-group-left .selectFilter span {
        top: 0px;
        left: 0px;
        font-size: 11px !important;
    }
    .detail-page-loader {
        height: calc(100vh - 59px);
    }
    .detail-page-loader .p-progress-spinner {
        margin-top: 0;
    }
}
