.searchResults {
    padding: 0px 16px;
}

.searchResults .result {
    cursor: pointer;
    transition: 0.3s;
    padding: 12px 16px;
}

.searchResults .result:hover {
    transition: 0.3s;
    background-color: #f1f2f4;
}

.searchResults .noresults {
    padding: 8px 16px;
}

.searchResultsLoader {
    padding: 0px 16px;
    text-align: center;
}

.searchResultsLoader .p-progress-spinner {
    width: 45px;
    height: 45px;
}
