.profileSidebarContent {
    padding: 40px 32px;
}

.profileSidebarContent .label {
    display: block;
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #464e54;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.profileSidebarContent .Name {
    font-family: AvenirLTPro;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.27px;
    color: #464e54;
    margin-bottom: 64px;
}

.profileSidebarContent .Email {
    margin-bottom: 48px;
}

.profileSidebarContent .Email .p-inputgroup .p-inputgroup-addon {
    background-color: #f1f2f4;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border: 0;
    padding-left: 27px;
    padding-right: 11px;
}

.profileSidebarContent .Email .p-inputgroup input.p-inputtext {
    height: 44px;
    padding: 10px 140px 10px 24px;
    border-radius: 30px;
    background-color: #f1f2f4;
    color: #7b8a9a;
    font-size: 14px;
    font-weight: 500;
    font-weight: 400;
    letter-spacing: 0.27px;
    border: 0;
    margin-left: -15px;
    padding: 0px 15px;
}

.profileSidebarContent .Email .p-inputgroup input.p-inputtext::-webkit-input-placeholder {
    color: #a2abb5;
}

.profileSidebarContent .Email .p-inputgroup input.p-inputtext:enabled:focus,
.profileSidebarContent .Email .p-inputgroup input.p-inputtext:enabled:hover {
    border: 0;
    box-shadow: inherit;
}

.profileSidebarContent .Languages {
    height: 125px;
    margin: 48px 0px 0px;
    padding: 16px 24px 24px;
    border-radius: 4px;
    border: solid 1px #e8ebed;
    background-color: #fff;
}

.profileSidebarContent .Languages .label {
    margin-bottom: 16px;
}

.profileSidebarContent .Languages .p-radiobutton,
.profileSidebarContent .Languages .p-radiobutton .p-radiobutton-box {
    width: 24px;
    height: 24px;
}

.profileSidebarContent .Languages .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #fff;
    background: #fff;
}

.profileSidebarContent .Languages .p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    background-color: #4072ee;
}

.profileSidebarContent .Languages .p-field-radiobutton {
    margin-bottom: 8px;
}

.profileSidebarContent .Languages .p-field-radiobutton > label {
    margin: 4px 0 3px 8px;
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #7b8a9a;
    margin-left: 8px;
}