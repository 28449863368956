@import '~react-datepicker/dist/react-datepicker-min.module.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.p-progress-spinner-svg {
    max-width: 45px;
}

.p-toolbar-group-left > div {
    position: relative;
}

.z-100 {
    z-index: 100;
}

button:focus {
    outline: none !important;
}

.p-calendar .p-datepicker {
    top: 45px !important;
}

