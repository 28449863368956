

.filter_calendars {
    justify-content: center;

    .custom_react_datepicker {

        &.hide_year_picker {}
    }

}