.searchInput {
    width: 100%;
    padding: 8px;
}

.searchInput .p-input-icon-left {
    width: 100%;
}

.searchInput .p-input-icon-left > .p-inputtext {
    width: 100%;
    height: 44px;
    color: #a2abb5;
    padding: 9px 16px;
    margin-bottom: 8px;
    border-radius: 30px;
    display: inline-flex;
    border: 2px solid #e8ebed;
    background-color: #ffffff;
    padding-left: calc(16px + 28px);
    outline: none !important;
    box-shadow: inherit !important;
}

.searchIcon {
    top: 11px;
    left: 18px;
    height: 21px;
    position: absolute;
}

.closeIcon {
    top: 8px;
    right: 18px;
    width: auto;
    height: 28px;
    cursor: pointer;
    position: absolute;
}
