.generalInfoBanners {
    padding: 5rem 1rem 1rem;
}

.generalInfoBanners .p-carousel .p-carousel-content .p-carousel-prev {
    margin-left: 4px;
    margin-right: 1rem;
}

.generalInfoBanners .p-carousel .p-carousel-content .p-carousel-next {
    margin-left: 1rem;
    margin-right: 4px;
}

.generalInfoBanners
    .p-carousel
    .p-carousel-content
    .p-carousel-items-content
    .p-carousel-item
    img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    object-position: center;
}

.generalInfoBanners .p-carousel-indicators {
    display: none;
}
