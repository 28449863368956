.myp_container {
    justify-content: center;
    display: flex;
    flex-direction: column;

    .myp_header {
        display: flex;
        justify-content: center;
        border: 1px solid #dee2e6;
        font-weight: bold;
        border-bottom: none;
        padding: 0.5em;
    }

    .myp_row {
        border: 1px solid #dee2e6;
        padding: 1em;
        margin: auto;

        .myp_item {
            display: flex;
            justify-content: center;
            color: #7b8a9a;
            cursor: pointer;
            padding: 0.5em;

            &_selected {
                @extend .myp_item;
                background-color: #4072ee;
                color: #fff;
            }
        }
    }
}

.p-grid {
    margin: auto;
}

.no_highlight {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
