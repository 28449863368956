/* List View */

.searchResult {
    margin: 0 0 8px;
    padding: 16px 16px 16px 19px;
    border-radius: 4px;
    border: solid 1px #e8ebed;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    grid-column-gap: 16px;
    cursor: pointer;
    transition: 0.3s;
}

.searchResult:hover {
    border: solid 1px #cccccc;
    transition: 0.3s;
}

.searchResult .searchResultDetails {
    max-width: calc(100% - 136px);
}

.searchResult .searchResultDetails .searchResultsDetailsHeader {
    margin-bottom: 8px;
}

.searchResult
    .searchResultDetails
    .searchResultsDetailsHeader
    .searchResultsDetailsHeaderImage {
    display: inline-block;
    width: 72px;
    height: 30px;
    margin: 0 12px 0 0;
    border-radius: 2px;
    vertical-align: middle;
    background-color: #fff;
}

.searchResult
    .searchResultDetails
    .searchResultsDetailsHeader
    .searchResultsDetailsHeaderFullImage {
    text-align: center;
    width: 100%;
    height: 200px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #e8ebed;
}

.searchResult
    .searchResultDetails
    .searchResultsDetailsHeader
    .searchResultsDetailsHeaderFullImage
    img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
}

.searchResult:hover
    .searchResultDetails
    .searchResultsDetailsHeader
    .searchResultsDetailsHeaderFullImage
    img {
    transform: scale(1.1);
    transition: 0.3s;
}

.searchResult
    .searchResultDetails
    .searchResultsDetailsHeader
    .searchResultsDetailsHeaderImage
    img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.searchResult
    .searchResultDetails
    .searchResultsDetailsHeader
    .searchResultsDetailsHeaderID,
.searchResult
    .searchResultDetails
    .searchResultsDetailsBody
    .searchResultsDetailsBodyID {
    display: inline-block;
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.7px;
    color: #535b64;
    vertical-align: middle;
}

.searchResult
    .searchResultDetails
    .searchResultsDetailsBody
    .searchResultsDetailsBodyTitle {
    font-family: AvenirLTPro;
    margin: 0 0 4px 0;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 0.15px;
    color: #464e54;
    transition: 0.3s;
    cursor: pointer;
    text-transform: capitalize;
}

/*
.searchResult
    .searchResultDetails
    .searchResultsDetailsBody
    .searchResultsDetailsBodyTitle:hover {
    opacity: 0.8;
    transition: 0.3s;
}
*/

.searchResult
    .searchResultDetails
    .searchResultsDetailsBody
    .searchResultsDetailsBodySubtitle {
    font-family: AvenirLTPro;
    margin: 0 0 17px 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.17px;
    color: #7b8a9a;
}

.searchResult
    .searchResultDetails
    .searchResultsDetailsBody
    .searchResultsDetailsBodyLabel {
    display: inline-block;
    margin: 0 8px 0 0;
    padding: 5px 8px 4px 9px;
    border-radius: 8.5px;
    background-color: #fa7b83;
    font-family: AvenirLTPro;
    font-size: 8px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    vertical-align: middle;
}

.searchResult
    .searchResultDetails
    .searchResultsDetailsBody
    .searchResultsDetailsBodyOwner {
    display: inline-block;
    margin: 0;
    font-family: AvenirLTPro;
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #535b64;
    text-transform: uppercase;
    vertical-align: middle;
}

.searchResult .searchResultThumbnail .searchResultThumbnailImage {
    overflow: hidden;
    height: 80px;
    margin-bottom: 16px;
    border-radius: 4px;
}

.searchResult .searchResultThumbnail .searchResultThumbnailImage img {
    width: 120px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
}

.searchResult:hover .searchResultThumbnail .searchResultThumbnailImage img {
    transition: 0.3s;
    transform: scale(1.1);
}

.searchResult .searchResultThumbnail .searchResultThumbnailFavourite {
    text-align: right;
}

.searchResult .searchResultThumbnail .searchResultThumbnailFavourite span {
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px 0 0;
    font-family: AvenirLTPro;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #7b8a9a;
}

.searchResult .searchResultThumbnail .searchResultThumbnailFavourite svg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    transition: 0.2s;
}

.searchResult
    .searchResultThumbnail
    .searchResultThumbnailFavourite.is-fav
    span,
.searchResult
    .searchResultThumbnail
    .searchResultThumbnailFavourite.is-fav
    svg {
    color: #fa7b83;
    filter: inherit;
}

/* Thumbnails View */

.searchResult.thumbnails {
    flex-direction: column-reverse;
    grid-column-gap: inherit;
}

.searchResult.thumbnails .searchResultDetails {
    max-width: 100%;
}

.searchResult.thumbnails .searchResultDetails .searchResultsDetailsHeader {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.searchResult.thumbnails .searchResultDetails .searchResultsDetailsHeader {
    margin-bottom: 16px;
}

.searchResult.thumbnails
    .searchResultDetails
    .searchResultsDetailsHeader
    .searchResultsDetailsHeaderImage {
    margin: 0;
    position: absolute;
    z-index: 10;
    left: 8px;
    bottom: 8px;
    border-radius: 4px;
}

.searchResult.thumbnails .searchResultDetails .searchResultsDetailsBody {
    display: flex;
    justify-content: space-between;
}

.searchResult.thumbnails .searchResultDetails .searchResultsDetailsBody > div {
    max-width: calc(100% - 60px);
}

@media (max-width: 991px) {
    .searchResult
        .searchResultDetails
        .searchResultsDetailsHeader
        .searchResultsDetailsHeaderFullImage {
        height: 120px;
    }
    .searchResult
        .searchResultDetails
        .searchResultsDetailsHeader
        .searchResultsDetailsHeaderImage
        img {
        padding: 0px 5px;
    }
    .searchResult.thumbnails .searchResultDetails .searchResultsDetailsBody {
        align-items: flex-end;
    }
}

