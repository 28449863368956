.fullScreen {
    position: absolute;
    top: 60px;
    right: 50px;
    z-index: 1000;
    width: 135px;
}

.fullScreenIcon {
    padding: 6px;
    border-radius: 25px;
    cursor: pointer;
}

.fullScreenIcon svg {
    width: 42px;
    height: 42px;
    margin: 0 auto;
}

.fullScreenCallToAction {
    color: #2196f3;
    background-color: white;
    border-radius: 25px;
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
    margin-top: 3px;
}

#slick-slider .slick-track {
    display: flex;
    height: calc(100vh - 61px);
}

#slick-slider .slick-slide {
    overflow: hidden;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}

#slick-slider .slick-slide > div,
#slick-slider .slick-slide > div > div {
    height: 100%;
    position: relative;
}

#slick-slider .prismaZoom {
    height: calc(100% - 110px);
    display: flex;
    align-items: center;
    -webkit-touch-callout: initial !important;
}

#slick-slider .slick-next,
#slick-slider .slick-prev {
    bottom: 0;
    z-index: 10;
    margin-top: -55px;
    width: 50px;
    height: 50px;
}

#slick-slider .slick-next:before,
#slick-slider .slick-prev:before {
    font-size: 50px;
}

#slick-slider .slick-next {
    right: 10px;
}

#slick-slider .slick-prev {
    left: 10px;
}

#slick-slider .carousel-item {
    bottom: 0;
    z-index: 30;
    width: 100%;
    height: 110px;
    padding: 5px 0px;
    position: absolute;
    background-color: #fff;
}

#slick-slider .mobile-macroarea {
    background-color: #f6f7f8;
    padding: 9px 16px;
    text-align: center;
    width: 100%;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #7b8a9a;
}

@media (min-width: 768px) and (max-width: 991px) {
    #slick-slider .slick-track {
        height: calc(100vh - 65px);
    }

    #slick-slider .slick-next,
    #slick-slider .slick-prev {
        width: 60px;
        height: 60px;
    }

    #slick-slider .slick-next:before,
    #slick-slider .slick-prev:before {
        font-size: 60px;
    }
}
