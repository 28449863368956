.mapTopLegend {
    width: 249px;
    width: auto;
    height: 40px;
    padding: 12px 16px;
    border-radius: 24.5px;
    border: solid 1px #c4c9cf;
    background-color: #fff;
    margin-top: 15px;
}

.mapTopLegend .flex.items-center {
    margin-right: 16px;
}

.mapTopLegend .flex.items-center:last-child {
    margin-right: 0;
}

.mapTopLegend .rounded-full {
    margin: 0;
}

.mapTopLegend .text-xs {
    width: 29px;
    width: auto;
    height: 10px;
    margin: 0 0 0 8px;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #7b8a9a;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .mapTopLegend {
        margin-top: 50px;
    }
}
